import React, { createContext, useContext, useState, useEffect } from 'react';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(null);
  const [focusRef, setFocusRef] = useState(null);

  useEffect(() => {
    if (focusRef && toast) {
      focusRef.focus();
    }
  }, [toast, focusRef]);

  const showToast = (message) => {
    setToast(message);
  };

  const hideToast = () => {
    setToast(null);
  };

  return (
    <ToastContext.Provider value={{ toast, showToast, hideToast }}>
      {children}
      {toast && (
        <div 
          className="toast-container"
          role="alert"
          aria-live="polite"
          aria-labelledby="toast-content"
        >
          <div className="toast">
            <div id="toast-content">{toast}</div>
            <button
              ref={setFocusRef}
              className="close-button"
              onClick={hideToast}
              aria-label="Close toast notification"
            >
              ×
            </button>
          </div>
        </div>
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);