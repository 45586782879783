import React from 'react';
import PropTypes from 'prop-types';

import Head from './Head';
import Header from './Header';
import Footer from './Footer';
import { ToastProvider } from '../components/global/toastContext';

const Layout = ({ children }) => (
  <>
    <ToastProvider>
      <Head />
      <div className="flex flex-col min-h-screen">
        <Header />
        <main id="main" className="flex-1">
          {children}
        </main>
        <Footer />
      </div>
    </ToastProvider>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
