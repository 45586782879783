/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import Layout from './src/layout/Layout';

import './src/styles/global.css';

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

export const onInitialClientRender = () => {
  // Wait for DOM to settle
  requestAnimationFrame(() => {
    const element = document.getElementById('#skip');
    if (element) {
      element.addEventListener('click', () => {
        document.getElementById('inhalt').focus();
      });
    }
  });
};